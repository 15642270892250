import React, { useState, useEffect, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';

import useAccount from '@api/queries/useAccount';
import useVehicle from '@api/queries/useVehicle';
import useScript, { Status } from '@hooks/useScript';
import { useConfig } from '@components/ConfigProvider';
import { useFeatureFlags } from '@components/FeatureFlags';

declare global {
  interface Window {
    embeddedservice_bootstrap: {
      settings: {
        language: string;
        orgId: string;
      };
      prechatAPI: {
        setHiddenPrechatFields: (fields: { IDMUserId: string; vin: string; oemName: string; path: string }) => void;
      };
      userVerificationAPI: {
        clearSession: () => Promise<void>;
      };
      init: (orgId: string, deploymentName: string, url: string, options: Record<string, string>) => void;
    };
  }
}

function LoadSalesforceChat() {
  const config = useConfig();
  const salesforceOrgId = config.get('SALESFORCE_ORG_ID') as string;
  const salesforceUrl = config.get('SALESFORCE_URL') as string;
  const salesforceScrtUrl = config.get('SALESFORCE_SCRT_URL') as string;
  const salesforceLibUrl = config.get('SALESFORCE_LIB_URL') as string;
  const salesforceDeploymentName = config.get('SALESFORCE_DEPLOYMENT_NAME') as string;
  const oemName = config.getOemName();
  const chatButtonEl = document.getElementById('embeddedMessagingConversationButton');
  const [buttonPosition, setButtonPosition] = useState({ bottom: 25, right: 30 });
  const [currentPathname, setCurrentPathname] = useState('');

  const { salesforceChat: isSalesforceChatEnabled } = useFeatureFlags();

  const { data: account } = useAccount();
  const { data: vehicle } = useVehicle();

  const accountId = account?._id || '';
  const vehicleVin = vehicle?.vin || '';

  const scriptUrl = isSalesforceChatEnabled ? salesforceLibUrl : undefined;
  const scriptStatus = useScript(scriptUrl);
  const isScriptReady = scriptStatus === Status.Ready;

  const initSalesforceChat = useCallback(
    (IDMUserId: string, vin: string, oemName: string, path: string) => {
      try {
        const embeddedserviceBootstrap = window.embeddedservice_bootstrap;
        embeddedserviceBootstrap.settings.language = 'en_US';

        window.addEventListener('onEmbeddedMessagingReady', (e) => {
          embeddedserviceBootstrap.prechatAPI.setHiddenPrechatFields({ IDMUserId, vin, oemName, path });
        });

        embeddedserviceBootstrap.init(salesforceOrgId, salesforceDeploymentName, salesforceUrl, {
          scrt2URL: salesforceScrtUrl,
        });

        console.log('Salesforce chat has been initiated successfully');
      } catch (err) {
        console.error('Failed to initiate Salesforce chat', err);
      }
    },
    [salesforceOrgId, salesforceDeploymentName, salesforceUrl, salesforceScrtUrl],
  );

  const resetSalesforceChat = useCallback(async () => {
    try {
      await window.embeddedservice_bootstrap.userVerificationAPI.clearSession();
      console.log('Salesforce chat has been cleared successfully');
    } catch (err) {
      console.error('Failed to clear Salesforce chat session', err);
    }
  }, []);

  const handleWrapperButtonClick = () => {
    setCurrentPathname(window.location.pathname);
    setTimeout(() => {
      chatButtonEl?.click();
    }, 300);
  };

  useEffect(() => {
    const handleUserChange = async () => {
      if (isSalesforceChatEnabled && isScriptReady) {
        if (accountId) {
          await resetSalesforceChat();
        }
        initSalesforceChat(accountId, vehicleVin, oemName, currentPathname);
      }
    };

    handleUserChange();
  }, [
    isSalesforceChatEnabled,
    isScriptReady,
    accountId,
    vehicleVin,
    initSalesforceChat,
    resetSalesforceChat,
    oemName,
    currentPathname,
  ]);

  useEffect(() => {
    if (chatButtonEl) {
      const rect = chatButtonEl.getBoundingClientRect();
      setButtonPosition({
        bottom: window.innerHeight - rect.bottom,
        right: window.innerWidth - rect.right,
      });
    }
  }, [chatButtonEl]);

  return (
    <IconButton
      size="large"
      sx={{
        position: 'fixed',
        bottom: buttonPosition.bottom,
        right: buttonPosition.right,
        width: 56,
        height: 56,
        zIndex: 999999,
      }}
      onClick={handleWrapperButtonClick}
    />
  );
}

export default LoadSalesforceChat;
